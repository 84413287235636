import * as React from "react"
import { Link } from "gatsby"
import Footer from "../components/page/footer/Footer";
import Spacer from "../components/spacer/Spacer";
import Helmet from "react-helmet";
import "../styles/404.scss";
import Page from "../components/page/Page";


// styles

const contentStyles = {
  flexGrow: 1,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const headingStyles = {
  textAlign: "center",
  marginBottom: '20px',
}

const paragraphStyles = {
  textAlign: "center",
  maxWidth: 600,
  fontSize: 24,
}

const linkStyles = {
  backgroundColor: '#4463FF',
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 40,
  paddingRight: 40,
  color: 'white',
  borderRadius: 6,
  marginTop: 20,
}



// markup



const NotFoundPage = () => {
  return (
    <Page>
      <Helmet>
        <title>Page Not found | Bravemark</title>
        <meta name="title" content="Page Not found | Bravemark" />
        <meta property="og:title" content="Page Not found" />
      </Helmet>
      <div style={contentStyles}>
        <Spacer amount="90" hideOnNarrowDevices={true} />
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          The page you are looking for does not exist. Please visit our homepage to get around again.
              <br /><br />
          <Link style={linkStyles} to="/">Go home</Link>
        </p>
        <Spacer amount="90" hideOnNarrowDevices={true} />
      </div>
      <Footer showPrivacySettings={false} />
      <Spacer amount="25" />
    </Page>
  )
}

export default NotFoundPage
